import { computed } from 'vue';
import { useQuery } from '@vue/apollo-composable';
import { gql } from '@apollo/client/core';

import { useNotification, useLoading } from '@/modules/core';

const UNPAID_BILLINGS_QUERY = gql`
  query unpaidBillings($businessId: ID!, $supplierId: ID) {
    unpaidBillings(businessId: $businessId, supplierId: $supplierId) {
      nodes {
        billingId
        totalAmount
        unpaidAmount
        date
      }
    }
  }
`;

export const useUnpaidBillings = (variables, options) => {
  const { error } = useNotification();
  const {
    result,
    loading: queryLoading,
    onError,
    refetch,
    onResult,
  } = useQuery(UNPAID_BILLINGS_QUERY, variables, options);
  const unpaidBillings = computed(() => result?.value?.unpaidBillings?.nodes ?? []);
  const loading = useLoading(queryLoading, variables);

  onError((err) => {
    console.error('agedDebtorsReport - useUnpaidBillings', err);
    error();
  });

  return { unpaidBillings, loading, refetch, onResult };
};
