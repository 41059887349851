<template>
  <div v-loading="loading">
    <div v-if="documents.length">
      <div class="d-flex justify-content-between align-items-center mb-5">
        <h3>
          {{ $t('payment.supplierPerspective.title', { supplier: supplier.name }) }}
        </h3>
        <div class="d-flex align-items-center">
          <p class="mx-2">
            {{
              $t('payment.supplierPerspective.documentsFrom', {
                documentType: capitalized(
                  documentType ? $t(`document.exports.schema.type.shortName.${documentType}`) : $t('commons.document')
                ),
              })
            }}
          </p>
          <el-select v-model="currentDocumentId" filterable size="mini">
            <el-option
              v-for="document in documents"
              :key="document.id"
              :label="formatDate(document.issueDate)"
              :value="document.id"
            />
          </el-select>
        </div>
      </div>
      <div>
        <Tabs
          class="flex-1"
          :tabs="[{ text: $t('document.modal.tab.file') }, { text: $t('document.modal.tab.digital') }]"
          :active-tab.sync="activeTab"
        />
        <div class="p-4" style="height: 70vh">
          <div v-if="activeTab === 0" class="pdf-viewer-container">
            <PdfViewer v-if="currentDocument" :src="currentDocument.filePathUrl" />
            <div v-else-if="docError">
              <h3>{{ $t('document.modal.documentFile.failedToLoad') }}</h3>
              <p>{{ $t('document.modal.documentFile.missingData') }}</p>
            </div>
          </div>
          <DigitalDocument
            v-if="activeTab === 1 && currentDocument"
            class="overflow-auto"
            :document="currentDocument"
          />
        </div>
      </div>
    </div>
    <h3 v-else-if="!loading">
      {{
        documentType
          ? $t(`payment.supplierPerspective.no${capitalized(documentType)}`, { supplier: supplier.name })
          : $t(`payment.supplierPerspective.noDocuments`, { supplier: supplier.name })
      }}
    </h3>
  </div>
</template>

<script type="text/javascript">
import { computed, ref, watch } from 'vue';

import { PdfViewer } from '@/modules/core';
import { Tabs } from '@/modules/core';
import { DigitalDocument, useDocumentNew2 } from '@/modules/documentModal';

import { formatDate } from '../../tools/formatters';

const DOC_TYPES = {
  AGED_DEBTORS_REPORT: 'agedDebtorsReport',
  RECONCILIATION_STATEMENT: 'reconciliationStatement',
};

export default {
  components: { PdfViewer, DigitalDocument, Tabs },
  props: {
    supplier: { type: Object, required: true },
    documentType: { type: String, default: null },
    documents: { type: Array, default: () => [] },
    documentsLoading: { type: Boolean, default: false },
  },
  setup(props) {
    const currentDocumentId = ref(props.documents[0]?.id);
    watch(
      () => props.documents,
      (newDocs) => {
        currentDocumentId.value = newDocs[0]?.id;
      },
      { immediate: true, deep: true }
    );
    const docError = ref(false);
    const {
      document: currentDocument,
      loading: documentLoading,
      onError,
    } = useDocumentNew2(
      () => ({ id: currentDocumentId.value }),
      () => ({ enabled: !!currentDocumentId.value })
    );
    onError(() => {
      docError.value = true;
    });

    return {
      docError,
      activeTab: ref(0),
      currentDocumentId,
      currentDocument,
      loading: computed(() => props.documentsLoading || documentLoading.value),
      formatDate,
      DOC_TYPES,
    };
  },
  watch: {
    currentDocument() {
      this.docError = false;
    },
  },
  methods: {
    capitalized(word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    },
  },
};
</script>
<style lang="scss" scoped>
.pdf-viewer-container {
  height: 100%;
  overflow: scroll;
}
</style>
