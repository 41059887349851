<template>
  <div>
    <h3 class="mb-4">
      {{ header }}
    </h3>
    <Table
      v-loading="loading"
      :columns="columns"
      :data="paginatedData"
      show-index
      border
      rounded
      :hover="false"
      class="mb-4"
      @row-click="displayedBillingId = paginatedData[$event].id"
    >
      <template #cell-index="{ rowIndex }">
        {{ rowIndex + 1 + PAGE_LIMIT * (currentPage - 1) }}
      </template>
      <template #cell-eventType="{ rowData: { document, type } }">
        <p v-if="type === 'balance'">{{ $t('payment.agedDebtorsReport.tenantPerspective.balanceBilling') }}</p>
        <p v-else>
          {{ $t(`document.exports.schema.type.fullName.${document.type}`) }}
        </p>
      </template>
      <template #cell-reference="{ rowData: { document } }">
        <Button v-if="document" type="link" class="p-0" @click.stop="displayedDocumentId = document.id">
          <p>{{ document.documentNumber }}</p>
        </Button>
        <p v-else>-</p>
      </template>
      <template #cell-debit="{ rowData: { debit, unpaidAmount } }">
        <template v-if="unpaidAmount === debit">
          <p>{{ formatMoney(debit) }}</p>
        </template>
        <template v-else-if="debit !== '-'">
          <p>{{ `${formatMoney(debit)} / ${formatMoney(unpaidAmount)}` }}</p>
        </template>
        <template v-else>
          {{ debit }}
        </template>
      </template>
      <template #cell-credit="{ rowData: { credit, unpaidAmount } }">
        <template v-if="unpaidAmount === credit">
          <p>{{ formatMoney(credit) }}</p>
        </template>
        <template v-else-if="credit !== '-'">
          <p>{{ `${formatMoney(credit)} / ${formatMoney(unpaidAmount)}` }}</p>
        </template>
        <template v-else>
          {{ credit }}
        </template>
      </template>
    </Table>
    <div v-if="!loading" class="d-flex justify-content-between align-items-center">
      <p class="fw-bold">{{ translate('totalAmount', { amount: totalAmount }) }}</p>
      <el-pagination
        v-if="unpaidBillings.length > paginatedData.length"
        layout="prev, pager, next, jumper"
        small
        background
        :current-page.sync="currentPage"
        :page-size="PAGE_LIMIT"
        :total="unpaidBillings.length"
        class="d-flex justify-content-end p-0"
      />
    </div>
    <EventMapModal
      v-if="displayedBillingId"
      :activity="{ type: 'billing', id: displayedBillingId }"
      @close="displayedBillingId = null"
    />
    <DocumentModal
      v-if="displayedDocumentId"
      visible
      :document-id="displayedDocumentId"
      @close="displayedDocumentId = null"
    >
      <template v-if="displayedDocumentId && documentIds.length > 1" #header>
        <el-pagination
          layout="prev, pager, next"
          small
          background
          :page-size="1"
          :total="documentIds.length"
          :current-page="documentIds.indexOf(displayedDocumentId) + 1"
          :pager-count="5"
          @update:current-page="displayedDocumentId = documentIds[$event - 1]"
        />
      </template>
    </DocumentModal>
  </div>
</template>

<script>
import { computed, ref } from 'vue';

import { Table, Button } from '@/modules/core';
import { DocumentModal } from '@/modules/documentModal';
import { useCurrency } from '@/modules/core/compositions/money-currency';

import { useCreatePayment, useBillings } from '../../../compositions';
import { useUnpaidBillings } from './compositions';
import { formatDate } from '../../../tools/formatters';

const PAGE_LIMIT = 20;
const TABLE_HEADER = {
  DATE: 'date',
  EVENT_TYPE: 'eventType',
  REFERENCE: 'reference',
  DEBIT: 'debit',
  CREDIT: 'credit',
};

export default {
  components: {
    Table,
    Button,
    EventMapModal: () => import('@/modules/eventMapModal/EventMapModal'),
    DocumentModal,
  },
  props: {
    supplierId: { type: String, required: true },
    businessId: { type: String, required: true },
    header: { type: String, default: '' },
  },
  setup(props) {
    const {
      unpaidBillings,
      loading: unpaidBillingsLoading,
      refetch: refetchUnpaidBillingsResult,
    } = useUnpaidBillings(
      () => ({ businessId: props.businessId, supplierId: props.supplierId }),
      () => ({ enabled: !!props.businessId && !!props.supplierId })
    );

    const currentPage = ref(1);
    const sortedUnpaidBillings = computed(() =>
      unpaidBillings.value.slice().sort((a, b) => new Date(a.date) - new Date(b.date))
    );
    const paginatedUnpaidBillings = computed(() =>
      sortedUnpaidBillings.value.slice((currentPage.value - 1) * PAGE_LIMIT, currentPage.value * PAGE_LIMIT)
    );

    const { billings, loading: billingsLoading } = useBillings(
      computed(() => ({
        businessId: props.businessId,
        ids: paginatedUnpaidBillings.value.map(({ billingId }) => billingId),
      }))
    );

    const forceLoading = ref(false);
    const { onDone } = useCreatePayment();
    onDone(async () => {
      forceLoading.value = true;
      await refetchUnpaidBillingsResult();
      forceLoading.value = false;
    });

    const { formatToCurrency } = useCurrency();

    const formatMoney = (value) => {
      if (typeof value === 'number' && !Number.isNaN(value)) {
        const number = Number(value.toFixed(2));
        const options = Number.isInteger(number) ? { maximumFractionDigits: 0 } : {};
        return formatToCurrency(value, options);
      }
      return '-';
    };

    return {
      formatMoney,
      PAGE_LIMIT,
      forceLoading,
      loading: computed(() => forceLoading.value || unpaidBillingsLoading.value || billingsLoading.value),
      unpaidBillings,
      billings,
      billingsById: computed(() =>
        billings.value.reduce((idsMap, billing) => {
          idsMap[billing.id] = billing;
          return idsMap;
        }, {})
      ),
      paginatedUnpaidBillings,
      currentPage,
      displayedDocumentId: ref(null),
      displayedBillingId: ref(null),
    };
  },
  computed: {
    columns() {
      return [
        {
          header: this.translate('table.header.date'),
          key: TABLE_HEADER.DATE,
        },
        {
          header: this.translate('table.header.eventType'),
          key: TABLE_HEADER.EVENT_TYPE,
        },
        {
          header: this.translate('table.header.reference'),
          key: TABLE_HEADER.REFERENCE,
        },
        {
          header: this.translate('table.header.debit'),
          key: TABLE_HEADER.DEBIT,
        },
        {
          header: this.translate('table.header.credit'),
          key: TABLE_HEADER.CREDIT,
        },
      ];
    },
    paginatedData() {
      return this.billings.length
        ? this.paginatedUnpaidBillings
            .filter(({ billingId }) => this.billingsById[billingId])
            .map(({ billingId, unpaidAmount }) => {
              const billing = this.billingsById[billingId];

              const document = billing.eventReferences[0].document;
              const billingTotalAmount = billing.totalAmount / 100;
              return {
                id: billing.id,
                [TABLE_HEADER.DATE]: formatDate(billing.date),
                [TABLE_HEADER.DEBIT]: billingTotalAmount > 0 ? billingTotalAmount : '-',
                [TABLE_HEADER.CREDIT]: billingTotalAmount < 0 ? billingTotalAmount : '-',
                unpaidAmount,
                document,
                type: billing.type,
              };
            })
        : [];
    },
    totalAmount() {
      return this.formatMoney(this.unpaidBillings.reduce((amount, { unpaidAmount }) => amount + unpaidAmount, 0));
    },
    documentIds() {
      return this.paginatedData.filter(({ document }) => document).map(({ document }) => document.id);
    },
  },
  methods: {
    translate(key, values) {
      return this.$t(`payment.agedDebtorsReport.tenantPerspective.${key}`, values);
    },
  },
};
</script>
