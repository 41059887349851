<template>
  <div>
    <div class="d-flex justify-content-between mb-4">
      <h3>
        {{ $t('payment.pastPayments.tabs.paymentsToBeMatched') }}
      </h3>
    </div>
    <div>
      <TableLoadingSkeleton v-if="loading" />
      <Table
        v-else
        :columns="columns"
        :data="tableData"
        show-index
        border
        rounded
        class="mb-4"
        @row-click="handleRowClick"
      >
        <template #cell-paymentDate="{ rowData: { paymentDate } }">
          <p>{{ formatDate(paymentDate) }}</p>
        </template>
        <template #cell-paidAmount="{ rowData: { paidAmount } }">
          <p>{{ formatMoney(paidAmount) }}</p>
        </template>
        <template #cell-approvedAt="{ rowData: { approvedAt } }">
          <p>{{ approvedAt ? formatDateTime(approvedAt) : '-' }}</p>
        </template>
      </Table>
    </div>
    <el-pagination
      v-if="payments.length > pageSize"
      :current-page.sync="currentPage"
      small
      layout="prev, pager, next, jumper"
      background
      :page-size="pageSize"
      :total="payments.length"
      class="d-flex justify-content-end p-0"
    />
    <EventMapModal v-if="activity" :activity="activity" @close="activity = null" />
  </div>
</template>

<script>
import { ref, computed } from 'vue';

import { Table, TableLoadingSkeleton } from '@/modules/core';
import { useCurrency } from '@/modules/core/compositions/money-currency';

import { formatBilledDate, formatDate, formatDateTime } from '../../tools/formatters';

const TABLE_HEADER = {
  PAYMENT_DATE: 'paymentDate',
  PAYMENT_METHOD: 'paymentMethod',
  SUPPLIER: 'supplier',
  INVOICE_COUNT: 'invoiceCount',
  INVOICES_DATE: 'invoicesDate',
  PAID_AMOUNT: 'paidAmount',
  APPROVED_BY: 'approvedBy',
  APPROVED_AT: 'approvedAt',
};

const PAGE_LIMIT = 50;

export default {
  components: { Table, EventMapModal: () => import('@/modules/eventMapModal/EventMapModal'), TableLoadingSkeleton },
  props: {
    suppliers: {
      type: Array,
      default: () => [],
    },
    payments: { type: Array, default: () => [] },
    loading: { type: Boolean, default: false },
  },
  setup(props) {
    const currentPage = ref(1);

    const sortedUnbilledPayments = computed(() =>
      [...props.payments].sort((a, b) => new Date(a.date) - new Date(b.date))
    );

    const paginatedUnbilledPayments = computed(() =>
      sortedUnbilledPayments.value.slice((currentPage.value - 1) * PAGE_LIMIT, currentPage.value * PAGE_LIMIT)
    );

    const { formatToCurrency } = useCurrency();

    const formatMoney = (value) => {
      if (typeof value === 'number' && !Number.isNaN(value)) {
        const number = Number(value.toFixed(2));
        const options = Number.isInteger(number) ? { maximumFractionDigits: 0 } : {};
        return formatToCurrency(value, options);
      }
      return '-';
    };

    return {
      pageSize: PAGE_LIMIT,
      currentPage,
      paginatedUnbilledPayments,
      formatMoney,
      formatDateTime,
      activity: ref(),
    };
  },
  computed: {
    columns() {
      return [
        {
          header: this.translate(`table.headers.${TABLE_HEADER.PAYMENT_DATE}`),
          key: TABLE_HEADER.PAYMENT_DATE,
          minWidth: '100px',
        },
        {
          header: this.translate(`table.headers.${TABLE_HEADER.SUPPLIER}`),
          key: TABLE_HEADER.SUPPLIER,
          minWidth: '150px',
        },
        {
          header: this.translate(`table.headers.${TABLE_HEADER.INVOICE_COUNT}`),
          key: TABLE_HEADER.INVOICE_COUNT,
          minWidth: '90px',
        },
        {
          header: this.translate(`table.headers.${TABLE_HEADER.INVOICES_DATE}`),
          key: TABLE_HEADER.INVOICES_DATE,
          minWidth: '110px',
        },
        {
          header: this.translate(`table.headers.${TABLE_HEADER.PAID_AMOUNT}`),
          key: TABLE_HEADER.PAID_AMOUNT,
          minWidth: '120px',
        },
        {
          header: this.translate(`table.headers.${TABLE_HEADER.PAYMENT_METHOD}`),
          key: TABLE_HEADER.PAYMENT_METHOD,
          minWidth: '120px',
        },
        {
          header: this.translate(`table.headers.${TABLE_HEADER.APPROVED_BY}`),
          key: TABLE_HEADER.APPROVED_BY,
          minWidth: '150px',
        },
        {
          header: this.translate(`table.headers.${TABLE_HEADER.APPROVED_AT}`),
          key: TABLE_HEADER.APPROVED_AT,
          minWidth: '190px',
        },
      ];
    },
    tableData() {
      return this.paginatedUnbilledPayments.map(
        ({ paymentId, date, supplierId, paidAmount, transactions, billingLinks, createdBy, createdAt }) => {
          const supplier = this.suppliers.find(({ id }) => id === supplierId);

          return {
            paymentId,
            [TABLE_HEADER.PAYMENT_DATE]: date,
            [TABLE_HEADER.PAYMENT_METHOD]: transactions.length
              ? `${this.$t(`payment.exports.paymentMethods.shortName.${transactions[0].paymentMethod}`)}`
              : this.translate(`noPaymentMethod`),
            [TABLE_HEADER.SUPPLIER]: supplier?.name ?? '-',
            [TABLE_HEADER.INVOICE_COUNT]: billingLinks.length,
            [TABLE_HEADER.INVOICES_DATE]: formatBilledDate(billingLinks.map(({ billing }) => billing)),
            [TABLE_HEADER.PAID_AMOUNT]: paidAmount,
            [TABLE_HEADER.APPROVED_BY]: createdBy
              ? `${createdBy.profile.firstName} ${createdBy.profile.lastName}`
              : '-',
            [TABLE_HEADER.APPROVED_AT]: createdBy && new Date(createdAt),
          };
        }
      );
    },
  },
  methods: {
    translate(key) {
      return this.$t(`payment.pastPayments.${key}`);
    },
    formatDate,
    handleRowClick(index) {
      this.activity = {
        type: 'payment',
        id: this.tableData[index].paymentId,
      };
    },
  },
};
</script>
